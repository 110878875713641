
import React, { Component } from 'react'
import { MDBRow, MDBCol, MDBContainer, MDBIcon } from 'mdbreact'
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { dashboardTableConfig, crewNavList, dashboardFiltersSearch, DashboardFiltersSearchJson, initialRenderList, dashboardPayload, clearDashboardPayload } from './config'
import TableComponent from '../SharedComponents/Table';
import { updateTableSortConfigObject } from '../../Common/TableHelper';
import './DashboardLatest.scss'
import SearchSelectField from '../SharedComponents/SearchSelectField';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import TablePagination from '@material-ui/core/TablePagination';
import SelectField from '../SharedComponents/SelectField/SelectField';
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import { withRouter } from "react-router-dom";
import { withUserContext } from "../../../contexts/UserContext";
import { withFilterContext } from "../../../contexts/FilterContext";
import Modal from '../../Common/MainViewModalLayout/Modal/Modal';
import { CircularProgress } from "@material-ui/core";
import { Link } from 'react-router-dom';
import ModalComponent from '../../Common/DefaultModalLayout/Modal/Modal';
import MessageModal from '../SharedComponents/MessageModal';
import BasicCheckbox from '../SharedComponents/BasicCheckbox/BasicCheckbox';

let expandedRecords = [];
class DashboardLatest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputFilters: JSON.parse(JSON.stringify({ ...dashboardFiltersSearch })),
            dashboardFilters: JSON.parse(JSON.stringify({ ...DashboardFiltersSearchJson })),
            config: JSON.parse(JSON.stringify({ ...dashboardTableConfig })),
            renderList: [],
            initialRenderList: [],
            dashboardRenderList: [],
            count: 0,
            page: 1,
            rowsPerPage: 25,
            icon: "sort-alpha-down",
            showNewProduction: false,
            isDashboardLoading: false,
            showCols: false,
            showMinCols: false,
            selectedCrewDetails: null,
            maxColErrorMessage: 'Max six columns are allowed for selection',
            minColErrorMessage: 'Min two columns should be selected',
            showMaxCols: 'Max Columns Alert',
            showMinColsAlert: 'Min Columns Alert',
            showHistoricalData: false,
            sortBy: "Value",
            isSortByAsc: true,
            sortCount: 0,
            dashboardPayload: JSON.parse(JSON.stringify({ ...dashboardPayload })),
            selectProjectData: null,
            selectedProjectBackup: null,
            selectedExpandedData: [],
            isFetchingData: false,
            selectedRowIndex: null,
            expandStatus: '>>',
            showSelectedColumnCount: 0
        }
    }
    getdefaultColumns = (obj) => {
        delete obj["agency_name"];
        delete obj["crew_id"];
        delete obj["crew_name"];
        delete obj["contact"];
        delete obj["crew_occupation"];
        delete obj["gl_no_prod"];
        delete obj["production_company"];
        delete obj["loanout_no"];
        delete obj["f_id"];
        delete obj["sag_id"];
    }
    componentDidMount() {
        this.getDashboardData(JSON.parse(JSON.stringify({ ...clearDashboardPayload })));
        this.fallBackFilterColumn();
        this.getProdCompanyDetails('PRODUCTION_COMPANIES');
        this.getStatusTypes()
        this.setState({ sortByValue: "PROJECT",
        page: this.props?.filterContext?.page ? (this.props?.filterContext?.page) : this.state?.page,
        rowsPerPage:this.props?.filterContext?.rowsPerPage ? (this.props?.filterContext?.rowsPerPage):this.state?.rowsPerPage
       })
    }

    navigationCallback = async (obj, node, rowIndex = null, e, project_id = null) => {
        let indexToInclude = 0;
        let clickedWord = e?.target?.innerText?.toLowerCase() || '';
        let tempObj = { ...obj }
        switch (node) {
            case "project_name":
                if (obj?.project_id != null) {
                    this.props?.history.push(`/caster-feature/project/${obj.project_id}`)
                } else {
                    this.props?.history.push(`/caster-feature/project/${project_id}`)
                }
                break;
            case "performer_name":
                if (obj?.project_id != null) {
                    if (obj.performer_name.includes("|")) {
                        indexToInclude = tempObj?.performer_name?.toLowerCase()?.split("|")?.indexOf(clickedWord);
                        if (indexToInclude >= 0) {
                            obj["performer_name"] = tempObj?.performer_name?.split("|")[indexToInclude];
                        }
                    }
                    if (obj.performer_deal_id.toString().includes("|")) {
                        if (indexToInclude >= 0) {
                            obj["performer_deal_id"] = tempObj?.performer_deal_id?.split("|")[indexToInclude];
                        }
                    }
                    this.props?.history.push(`/caster-feature/project/${obj.project_id}`);
                    this.props?.handleTabChange(Number("7"), "performer_name", obj);
                } else {
                    this.props?.history.push(`/caster-feature/project/${project_id}`);
                    obj["project_id"] = project_id;
                    this.props?.handleTabChange(Number("7"), "performer_name", obj);
                }
                break;
            case "role":
                if (obj?.project_id != null) {
                    this.props?.history.push(`/caster-feature/project/${obj.project_id}`);
                    if (obj?.role?.includes("|")) {
                        indexToInclude = tempObj?.role?.toLowerCase()?.split("|")?.indexOf(clickedWord);
                        if (indexToInclude >= 0) {
                            obj["role"] = tempObj?.role?.split("|")[indexToInclude];
                        }

                    }
                    if (obj?.performer_deal_id?.toString()?.includes("|")) {
                        if (indexToInclude >= 0) {
                            obj["performer_deal_id"] = tempObj?.performer_deal_id?.split("|")[indexToInclude];
                        }
                    }
                    this.props?.handleTabChange(Number("0"), "role", obj);
                } else {
                    this.props?.history.push(`/caster-feature/project/${project_id}`)
                    obj["project_id"] = project_id;
                    this.props?.handleTabChange(Number("0"), "role", obj);
                }
                break;
            case "representative_name":
                if (obj?.project_id != null) {
                    this.props?.history.push(`/caster-feature/project/${obj.project_id}`);
                    if (obj.representative_name.includes("|")) {
                        indexToInclude = tempObj?.representative_name?.toLowerCase()?.split("|")?.indexOf(clickedWord);
                        if (indexToInclude >= 0) {
                            obj["representative_name"] = tempObj?.representative_name?.split("|")[indexToInclude];
                        }

                    }
                    if (obj.performer_deal_id?.toString()?.includes("|")) {
                        if (indexToInclude >= 0) {
                            obj["performer_deal_id"] = tempObj?.performer_deal_id?.split("|")[indexToInclude];
                        }
                    }
                    this.props?.handleTabChange(Number("1"), "representative_name", obj);
                } else {
                    this.props?.history.push(`/caster-feature/project/${project_id}`);
                    obj["project_id"] = project_id;
                    this.props?.handleTabChange(Number("1"), "representative_name", obj);
                }
                break;
            case "agency_name":
                if (obj?.project_id != null) {
                    this.props?.history.push(`/caster-feature/project/${obj.project_id}`);
                    if (obj.agency_name.includes("|")) {
                        indexToInclude = tempObj?.agency_name?.toLowerCase()?.split("|")?.indexOf(clickedWord);
                        if (indexToInclude >= 0) {
                            obj["agency_name"] = tempObj?.agency_name?.split("|")[indexToInclude];
                        }

                    }
                    if (obj.performer_deal_id?.toString()?.includes("|")) {
                        if (indexToInclude >= 0) {
                            obj["performer_deal_id"] = tempObj?.performer_deal_id?.split("|")[indexToInclude];
                        }
                    }
                    this.props?.handleTabChange(Number("1"), "agency_name", obj);v
                } else {
                    this.props?.history.push(`/caster-feature/project/${project_id}`);
                    obj["project_id"] = project_id;
                    this.props?.handleTabChange(Number("1"), "agency_name", obj);
                }
                break;
            case "crew_name":
                if (typeof tempObj?.crew_name === 'object') {
                    const indexToInclude = tempObj?.crew_name.findIndex(i => i?.crew_name?.toLowerCase() === clickedWord);
                    obj.crew_name = tempObj?.crew_name?.[indexToInclude]?.crew_name;
                            obj.crew_id = tempObj?.crew_name?.[indexToInclude]?.crew_id;
                            obj.crew_occupation = tempObj?.crew_name?.[indexToInclude]?.crew_occupation

                            
                            //this.handleShowCrewModal()
                            this.props?.history.push(`/caster-feature/project/${project_id}`);
                            this.props?.handleTabChange(Number("4"), "crew_name", obj);
                    // this.setState({
                    //     selectedCrewDetails: {
                    //         value: tempObj?.crew_name?.[indexToInclude]?.crew_id,
                    //         headerName: tempObj?.crew_name?.[indexToInclude]?.crew_name,
                    //         text: tempObj?.crew_name?.[indexToInclude]?.crew_name
                    //     }
                    // },
                    //     () => {
                    //         console.log('project_id',project_id);
                    //         obj.crew_name = tempObj?.crew_name?.[indexToInclude]?.crew_name;
                    //         obj.crew_id = tempObj?.crew_name?.[indexToInclude]?.crew_id;
                    //         obj.crew_occupation = tempObj?.crew_name?.[indexToInclude]?.crew_occupation
                    //         //this.handleShowCrewModal()
                    //         this.props?.history.push(`/caster-feature/project/${project_id}`);
                    //         this.props?.handleTabChange(Number("4"), "crew_name", obj);
                 
                           
                           
                    //        console.log('obj',obj);
                    //     }
                    // );
                } else {
                    if (obj.crew_name.includes("|")) {
                        indexToInclude = tempObj?.crew_name?.toLowerCase()?.split("|")?.indexOf(clickedWord);
                        if (indexToInclude >= 0) {
                            obj["crew_name"] = tempObj?.crew_name?.split("|")[indexToInclude];
                        }

                    }
                    if (obj.crew_id.includes("|")) {
                        if (indexToInclude >= 0) {
                            obj["crew_id"] = tempObj?.crew_id?.split("|")[indexToInclude];
                        }
                    }
                    this.props?.history.push(`/caster-feature/project/${project_id}`);
                    this.props?.handleTabChange(Number("4"), "crew_name", obj);
                    // this.setState({
                    //     selectedCrewDetails: {
                    //         value: obj?.crew_id,
                    //         headerName: obj?.crew_name,
                    //         text: obj?.crew_name
                    //     }
                    // },
                    //     () => {
                    //       //  console.log(this.state.selectedCrewDetails);
                    //         console.log('project_id',project_id);
                    //         console.log('obj',obj);
                    //        // this.handleShowCrewModal()
                    //        this.props?.history.push(`/caster-feature/project/${project_id}`);
                    //        this.props?.handleTabChange(Number("4"), "crew_name", obj);
                    //     }
                    // );

                }


                  //this.props?.handleTabChange(Number("4"), "crew_name", obj);

                break;
            case "expand":
                expandedRecords = [];
                let payload = JSON.parse(atob(dashboardPayload.encryptedString));
                payload.project_id = obj?.project_id;
                payload.show_historical_data = this.state.showHistoricalData;
                payload = btoa(JSON.stringify(payload));
                dashboardPayload.encryptedString = payload;                
                this.setState({ selectProjectData: null, selectedExpandedData: [], selectedRowIndex: rowIndex });
                const load = JSON.parse(JSON.stringify({ ...dashboardPayload }));
                if (obj.expand === ">>") {
                    if (this.state.selectedProjectBackup) {
                        let tempInitialRenderList = JSON.parse(JSON.stringify([...this.state.initialRenderList]));
                        let indexTemp = tempInitialRenderList.findIndex(item => item.project_id == this.state.selectedProjectBackup?.project_id);
                        tempInitialRenderList[indexTemp] = this.state.selectedProjectBackup;
                        this.setState({ initialRenderList: tempInitialRenderList, selectedProjectBackup: null }, () => {
                            this.getProjectbyProjectId(load, obj);
                            this.setState({ selectedProjectBackup: obj });
                        })
                    } else {
                        this.getProjectbyProjectId(load, obj);
                        this.setState({ selectedProjectBackup: obj });
                    }
                } else {
                    let tempInitialRenderList = JSON.parse(JSON.stringify([...this.state.initialRenderList]));
                    let indexTemp = tempInitialRenderList.findIndex(item => item.project_id == obj.project_id);
                    tempInitialRenderList[indexTemp] = this.state.selectedProjectBackup;

                    //tempInitialRenderList[indexTemp].expand = ">>";
                    // this.getdefaultColumns(tempInitialRenderList[indexTemp]);
                    this.setState({ initialRenderList: tempInitialRenderList, selectedProjectBackup: null })
                 //   let config = this.state.config;
                  //  config.headings.map((item) => { item.defaultColumn === true ? item.isColumn = true : item.isColumn = false });
                }

                break;
            default:
                break;
        }

    }

    getProjectbyProjectId = async (load, obj = null) => {
        this.setState({ isFetchingData: true });
        await CasterService.getDataWS(load).then(
            (webSocketConn) =>
                (webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data);
                    if (response?.statusCode === 200) {
                        let formattedList = response?.body?.data[0];
                        let finalFormattedList = [];
                        let performerList = formattedList?.performer;
                        // if(response?.body?.data.length <= 0 ){
                        //     this.setState({isFetchingData: false})
                        // }
                        if (formattedList) {
                            for (let i = 0; i < performerList?.length; i++) {
                                finalFormattedList.push({
                                    "project_id": formattedList?.project_id,
                                    "project_name": i === 0 ? formattedList?.project_name + "|" + formattedList?.project_aka : '',
                                    "status": formattedList?.status ? formattedList?.status : " ",
                                    "gl_no_prod": i === 0 && formattedList?.gl_no_prod ? formattedList?.gl_no_prod : " ",
                                    "production_company": performerList[i]?.production_company != null ? performerList[i]?.production_company : '',
                                    "performer_deal_id": performerList[i]?.performer_deal_id != null ? performerList[i]?.performer_deal_id : '',
                                    "performer_name": performerList[i]?.performer_name != null ? performerList[i]?.performer_name : '',
                                    "role": performerList[i]?.role != null ? performerList[i]?.role : '',
                                    "ssn": performerList[i]?.ssn != null ? performerList[i]?.ssn : '',
                                    "representative_name": performerList[i]?.representative,
                                    "representative_phone": performerList[i]?.representative,
                                    "representative_email": performerList[i]?.representative,
                                    "union": performerList[i]?.union != null ? performerList[i]?.union : '',
                                    "isSSNVisible": false,
                                    "expand": "",
                                    "expandDetail": "<<",
                                    "agency_name": performerList[i]?.representative,
                                    "crew_id": "",
                                    "crew_name": i === 0 && formattedList?.crew ? formattedList?.crew : ' ',
                                    "performer_phone": performerList[i]?.performer_phone != null ? performerList[i]?.performer_phone : '',
                                    "performer_email": performerList[i]?.performer_email != null ? performerList[i]?.performer_email : '',
                                    "crew_occupation": i === 0 && formattedList?.crew ? formattedList?.crew : ' ',
                                    "loanout_company": performerList[i]?.loanout_no != null ? performerList[i]?.loanout_company : '',
                                    "f_id": performerList[i]?.f_id != null ? performerList[i]?.f_id : '',
                                    "e_in": performerList[i]?.f_id != null ? performerList[i]?.e_in:'',    
                                    "sag_id": performerList[i]?.sag_id != null ? performerList[i]?.sag_id : '',
                                    "performer": formattedList?.performer,
                                });
                            }
                        }
                      
                        this.setState({ selectProjectData: finalFormattedList, selectedExpandedData: finalFormattedList, expandStatus: '<<' }, () => {
                            this.expandProjectDatabyId(obj);
                        });
                    }else{
                        this.setState({ isFetchingData: false });
                    }
                })
        );
    }

    expandProjectDatabyId(obj) {
        let config = this.state.config;
        let tempInitialRenderList = JSON.parse(JSON.stringify([...this.state.initialRenderList]));
        let indexTemp = tempInitialRenderList.findIndex(item => item.project_id == obj.project_id);
        let tempDashboardRenderList = this.state.selectProjectData[0];
        if (obj.expand === ">>") {
           // config.headings.map((item) => { item.isColumn = true });
            if (tempDashboardRenderList.project_id == obj.project_id) {
                tempInitialRenderList[indexTemp] = JSON.parse(JSON.stringify({ ...tempDashboardRenderList }));
                tempInitialRenderList[indexTemp].expand = "<<";
            } else {
                if (tempInitialRenderList[indexTemp].expand === ">>")
                    this.getdefaultColumns(tempInitialRenderList[indexTemp]);
            }
        }
        this.setState({ config, isFetchingData: false, initialRenderList: tempInitialRenderList })
    }

    handleHideProductionPopup() {
        this.setState({ showNewProduction: false })
    }

    getStatusTypes = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=PROJECT_STATUS`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.lookup_value, text: item.lookup_value }));
                this.setState({ statusOptions: formattedList });
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err)
                })
    }

    getProdCompanyDetails(objectType, searchString = null) {
        this.setState({ isRepresentativeFetch: true });
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/casterFeatureSearch?objectType=${objectType}&searchString=`
        ).then(
            (response) => {
                this.setState({ isRepresentativeFetch: false });
                if (response?.data?.error) {
                    console.log("error from search", repsonse?.data?.error);
                } else {
                    let formattedList = response?.data?.map((i) => ({
                        value: i?.production_company,
                        text: i?.production_company,
                    }));
                    this.setState({ prodCompanyOptions: formattedList });

                }
            },
            (err) => {
                this.setState({ isRepresentativeFetch: false });
                console.log("Search from reprsentative: " + err);
            }
        );
    }

    handleFavIcon = (item, id) => {
        this.setState({ isDashboardLoading: true });
        const data = { project_id: item.project_id, user_id: this.props?.userContext?.user_profile?.user_id };
        if (item.is_favorite === 1) {
            data["is_delete"] = 1
            data["user_project_favorite_id"] = item?.user_project_favorite_id
        } else if (item.is_favorite === 0) {
            data["is_delete"] = 0
            data["user_project_favorite_id"] = null
        }
        CasterService.postDataParams(
            Constants.CasterServiceBaseUrl + "/casterFeatureProjectFavorite", data, this.props.userContext?.active_tenant?.tenant_id,
        )
            .then((res) => { this.getDashboard(this.state.inputFilters) })
            .catch((error) => {
                this.setState({ isDashboardLoading: false });
                console.log(error, "fav error");
            });
    }

    getFeatureDashboardProjects = (value = null) => {
        let inputFilters = this.state.inputFilters
        inputFilters["search_text"] = value;
        this.setState({ inputFilters }, () => {
            this.getDashboard(this.state.inputFilters)
        })
    };
    getDashboardData = (load) => {
        this.setState({ isFetchingData: true });
        CasterService.getDataWS(load).then(
            (webSocketConn) =>
                (webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data);
                    if (response?.statusCode === 200) {
                        this.setState({ isDashboardLoading: false });
                       
                        let result = JSON.parse(JSON.stringify({ ...response?.body[0] }));
                        if(response?.body[0]?.count === 0 ){
                            this.setState({ renderList: [], count: result?.count, 
                                lastAccessedProjectId: result?.last_accessed_project_id,
                                 last_accessed_project: result?.last_accessed_project,
                                 isFetchingData: false, 
                                  initialRenderList:[] })

                        }
                        let formattedList = JSON.parse(JSON.stringify([...response?.body[0]?.data]));
                        let finalFormattedList = [];
                       
                        if (formattedList.length > 0) {
                            for (let i = 0; i < formattedList.length; i++) {
                                finalFormattedList.push({
                                    "project_id": formattedList[i]?.project_id,
                                    "project_name": formattedList[i]?.project_name + "|" + formattedList[i]?.project_aka,
                                    // (formattedList[i]?.project_aka?.includes(",") ? formattedList[i]?.project_aka?.split(",")?.join("|") : formattedList[i]?.project_aka),
                                    "status": formattedList[i]?.status ? formattedList[i]?.status : " ",
                                    "gl_no_prod": formattedList[i]?.gl_no_prod ? formattedList[i]?.gl_no_prod : " " ,
                                    "production_company": formattedList[i]?.performer[0]?.production_company,
                                    "performer_deal_id": formattedList[i]?.performer[0]?.performer_deal_id,
                                    "performer_name": formattedList[i]?.performer[0]?.performer_name,
                                    "role": formattedList[i]?.performer[0]?.role,
                                    "ssn": formattedList[i]?.performer[0]?.ssn,
                                    "representative_name": formattedList[i]?.performer[0]?.representative,
                                    "representative_phone": formattedList[i]?.performer[0]?.representative,
                                    "representative_email": formattedList[i]?.performer[0]?.representative,
                                    "union": formattedList[i]?.performer[0]?.union,
                                    "isSSNVisible": false,
                                    "expand": ">>",
                                    "expandDetail": ">>",
                                    "agency_name": formattedList[i]?.performer[0]?.representative,
                                    "crew_id": "",
                                    "crew_name": "",
                                    "performer_phone": formattedList[i]?.performer[0]?.performer_phone,
                                    "performer_email": formattedList[i]?.performer[0]?.performer_email,
                                    "crew_occupation": "",
                                    "loanout_company": formattedList[i]?.performer[0]?.loanout_company,
                                    "f_id": formattedList[i]?.performer[0]?.f_id,
                                    "e_in": formattedList[i]?.performer[0]?.e_in,                                    
                                    "sag_id":formattedList[i]?.performer[0]?.sag_id,
                                    "performer": formattedList[i]?.performer,
                                });
                                // if (formattedList[i]?.performer?.length <= 0) {
                                //     finalFormattedList[i]["performer_name"] = " ";
                                //     finalFormattedList[i]["role"] = " ";
                                //     finalFormattedList[i]["ssn"] = " ";
                                //     finalFormattedList[i]["union"] = " ";
                                //     finalFormattedList[i]["production_company"] = " ";
                                //     finalFormattedList[i]["representative_name"] = " ";
                                //     finalFormattedList[i]["f_id"] = " ";
                                //     finalFormattedList[i]["sag_id"] = " ";
                                //     finalFormattedList[i]["loanout_no"] = " ";
                                //     finalFormattedList[i]["agency_name"] = " ";
                                // }
                                if (i!= 0 && formattedList[i]?.performer?.length > 0) {
                                    for (let j = 0; j < formattedList[i].performer?.length; j++) {
                                        formattedList[i].performer[j]["agency_name"] = formattedList[i]?.performer[j]?.representative ;  
                                       // formattedList[i].performer[j]["expand"] = ">>" ;                                        
                                    }
                                }
                                if (formattedList[i]?.crew?.length > 0) {
                                    for (let j = 0; j < formattedList[i].crew?.length; j++) {
                                        finalFormattedList[i]["crew_id"] += formattedList[i]?.crew[j]?.crew_id + (j < formattedList[i].crew?.length - 1 ? "|" : "");
                                        finalFormattedList[i]["crew_name"] += formattedList[i]?.crew[j]?.crew_name + (j < formattedList[i].crew?.length - 1 ? "|" : "");
                                        finalFormattedList[i]["crew_occupation"] += formattedList[i]?.crew[j]?.crew_occupation + (j < formattedList[i].crew?.length - 1 ? "|" : "");
                                    }
                                } else {
                                    finalFormattedList[i]["crew_id"] = " ";
                                    finalFormattedList[i]["crew_name"] = " ";
                                    finalFormattedList[i]["crew_occupation"] = " ";
                                }

                            }
                        }
                        let initialRenderList = JSON.parse(JSON.stringify([...finalFormattedList]));
                        // for (let i = 0; i < initialRenderList.length; i++) {
                        //     delete initialRenderList[i]["agency_name"];
                        //     delete initialRenderList[i]["crew_id"];
                        //     delete initialRenderList[i]["crew_name"];
                        //     delete initialRenderList[i]["contact"];
                        //     delete initialRenderList[i]["crew_occupation"];
                        //     delete initialRenderList[i]["gl_no_prod"];
                        //     delete initialRenderList[i]["production_company"];
                        //     delete initialRenderList[i]["loanout_company"];
                        //     delete initialRenderList[i]["e_in"];                            
                        //     delete initialRenderList[i]["f_id"];
                        //     delete initialRenderList[i]["sag_id"];
                        // }
                        this.setState({ renderList: finalFormattedList, count: result?.count, lastAccessedProjectId: result?.last_accessed_project_id, last_accessed_project: result?.last_accessed_project, backUpList: finalFormattedList, isDashboardLoading: false, isFetchingData: false, initialRenderList })
                    }else{
                        this.setState({ isFetchingData: false });
                    }
                    // }else{
                    //   this.setState({ postInitiated: false })
                    //   this.notificationComponent(true,"fail")
                    // }
                })
        );
    }
    // getDashboard = (inputFilters) => {
    //     this.setState({ isDashboardLoading: true })
    //     CasterService.getDataParams(Constants.CasterServiceBaseUrl +
    //         `/casterFeatureDashboardProject`, inputFilters, this.props.userContext?.active_tenant?.tenant_id)
    //         .then(response => {
    //             let formattedList = response?.data?.data?.map(i =>
    //                 ({
    //                     project_id: i?.project_id,
    //                     production_company: i?.production_company ? i?.production_company : "--",
    //                     project_name: i?.project_name ? i?.project_name : "--",
    //                     gl_no_prod: i?.gl_no_prod ? i?.gl_no_prod : "--",
    //                     gl_no_dev: i?.gl_no_dev ? i?.gl_no_dev : "--",
    //                     start_wrap_date: i?.start_date ? `${i?.start_date}${i?.wrap_date ? '-' + i?.wrap_date : ""}` : "--",
    //                     status: i?.status ? i?.status : "--",
    //                     is_favorite: i?.is_favorite,
    //                     user_project_favorite_id: i?.user_project_favorite_id
    //                 }))
    //             this.setState({ renderList: formattedList, count: response?.data?.count, lastAccessedProjectId: response?.data?.last_accessed_project_id, last_accessed_project: response?.data?.last_accessed_project, backUpList: formattedList, isDashboardLoading: false })

    //         },
    //             (err) => {
    //                 this.setState({ isDashboardLoading: false })
    //                 console.log("Error in fetching Representative Details:", err)
    //             })
    // }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage + 1,
        }, () => {
            let paginationData = JSON.parse(atob(dashboardPayload.encryptedString));
            paginationData.show_historical_data = this.state.showHistoricalData;
            paginationData.per_page = event.target.value;
            paginationData.page = this.state.page;
            paginationData.project_id = '';
            const payload = btoa(JSON.stringify(paginationData));
            dashboardPayload.encryptedString = payload;
            this.getDashboardData(dashboardPayload);
            this.props?.userContext?.filterTableData( 
                {
                    navigation: null, 
                    filterValues: null,
                    inputValues: null,
                    page:this.state.page,
                    rowsPerPage: this.state.rowsPerPage
                   }
        );
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: event.target.value,
            page: 1,
        }, () => {
            let paginationData = JSON.parse(atob(dashboardPayload.encryptedString));
            paginationData.show_historical_data = this.state.showHistoricalData;
            paginationData.per_page = this.state.rowsPerPage;
            paginationData.page = this.state.page;
            paginationData.project_id = '';
            const payload = btoa(JSON.stringify(paginationData));           
            dashboardPayload.encryptedString = payload;
            this.getDashboardData(dashboardPayload);
            this.props?.userContext?.filterTableData(
                {
                    navigation: null, 
                    filterValues: null,
                    inputValues: null,
                    page:this.state.page,
                    rowsPerPage: this.state.rowsPerPage
                   }
        );
        });
    }

    // getProjectNamegetProjectName = (searchString) => {
    //     let json = {
    //         per_page: 25,
    //         page: 1,
    //         order_by: "PROJECT",
    //         sort_by: "ASC",
    //         search_text: searchString
    //     }
    //     CasterService.getDataParams(Constants.CasterServiceBaseUrl + `/casterFeatureDashboardProject`, json, this.props.userContext?.active_tenant?.tenant_id)
    //         .then((response) => {
    //             if (response?.data?.error) {
    //                 console.log('error from search', repsonse?.data?.error)
    //             } else {
    //                 let formattedList = response?.data?.data?.map(i => ({
    //                     value: i?.project_id,
    //                     text: `${i?.project_name ? i?.project_name : ''}${i?.aka ? '(' + i?.aka + ')' : ''}${i?.gl_no_dev ? '(' + i?.gl_no_dev + ')' : ''}${i?.gl_no_prod ? '(' + i?.gl_no_prod + ')' : ''}`
    //                 }))
    //                 this.setState({ projectOptions: formattedList })
    //                 // console.log("post response", response);
    //             }
    //         },
    //             (err) => {
    //                 console.log("Post agency details error: " + err);
    //             });
    // }

    // getSortByValues = (field, value) => {
    //     let inputFilters = this.state.inputFilters
    //     if (field === "order_by") {
    //         inputFilters["order_by"] = value
    //         this.setState({ sortByValue: value })
    //     } else if (field === "production_companies") {
    //         inputFilters["production_companies"] = value?.value
    //         this.setState({ productionCompanySelected: value })
    //     } else if (field === "project_status") {
    //         inputFilters["project_status"] = value?.value
    //         this.setState({ projectStatusSelected: value })
    //     }
    //     inputFilters["page"] = 1
    //     this.setState({
    //         inputFilters,
    //     }, () => {
    //         this.getDashboard(this.state.inputFilters)
    //     })
    // }


    handleSelectedShow = (newValue) => {
        this.setState({
            selectedShow: newValue,
        });
    };

    // tableSort = () => {
    //     let inputFilters = this.state.inputFilters
    //     let icon = this.state.icon
    //     if (inputFilters["sort_by"] === "ASC") {
    //         inputFilters["sort_by"] = "DESC"
    //         icon = "sort-alpha-up"
    //     } else {
    //         inputFilters["sort_by"] = "ASC"
    //         icon = "sort-alpha-down"
    //     }
    //     this.setState({
    //         inputFilters,
    //         icon
    //     }, () => {
    //         this.getDashboard(this.state.inputFilters)
    //     })
    // }


    handleSelectedProject = (obj) => {
        if (obj?.value) {
            this.props.history.push(`/caster-feature/project/${obj?.value}`)
        }
    }

    fallBackFilterColumn() {
        let config = this.state.config;
        config?.headings.map(item =>{
            if(!item?.isColumn){
                  Object.keys(this.props?.filterContext?.inputValues).find(key => {
                return item.isColumn = (this.props?.filterContext?.inputValues[key]?.length > 0 &&
                    (key?.toLowerCase() === item?.headingLabel?.toLowerCase()))?true:false
            })
        }}
        );
       
        let count = config.headings.filter(item => item.isColumn === true).length;
        this.setState({ config: config,showSelectedColumnCount:count });
    }

    onColumnCheck = (element, index, checked) => {
        let config = this.state.config;
        let count = config.headings.filter(item => item.isColumn === true).length;
        console.log('count', count );
        let tempRenderList = [...this.state.initialRenderList];
        // if ((count - 1) > 5 && checked) {
        //     this.setState({ showCols: true })
        //     // alert('Max ten columns are allowed for selection')
        // }
        // else 
        if(count && checked){
            this.setState({ showSelectedColumnCount: count+1});
        }else{
            this.setState({ showSelectedColumnCount: count-1});
        }
        if ((count - 1) <= 1 && !checked) {
            this.setState({ showMinCols: true })
            // alert('Min two columns are allowed for selection')
        }
        else {
            config.headings[index].isColumn = checked;

            if (checked) {
                if (element.dataNode === "crew_name") {
                    for (let i = 0; i < tempRenderList.length; i++) {
                        tempRenderList[i][element.dataNode] = this.state.backUpList[i][element.dataNode];
                        tempRenderList[i]["crew_id"] = this.state.backUpList[i]["crew_id"];
                    }
                } else {
                    for (let i = 0; i < tempRenderList.length; i++) {
                        tempRenderList[i][element.dataNode] = this.state.backUpList[i][element.dataNode];
                    }
                }

            } else {
                for (let i = 0; i < tempRenderList.length; i++) {
                    delete tempRenderList[i][element.dataNode];
                }
            }
          //  
           
            this.setState({ config: config, initialRenderList: tempRenderList });

        }
    }

    applyFilterLandingPage = (filterValues, filtersNone, dropDownValues) => {
        let payload = JSON.parse(atob(dashboardPayload.encryptedString));
        filterValues?.map(filter => {
            let filterKeyValuePair = filter?.split(":");
           // return payload[filterKeyValuePair[0]] = filterKeyValuePair[1].trim();
           return payload[filterKeyValuePair[0]] = filter.replace(filterKeyValuePair[0].toString()+':',"").trim();
        })
        payload.show_historical_data = this.state.showHistoricalData;
        payload.page = 1;
        payload.per_page = this.state.rowsPerPage;
        payload.project_id = '';
        payload = btoa(JSON.stringify(payload));
        dashboardPayload.encryptedString = payload;
        const load = JSON.parse(JSON.stringify({ ...dashboardPayload }));
        this.setState({ page: 1 })
        this.getDashboardData(load);
        this.props?.userContext?.filterTableData( 
            {
                navigation: null, 
                filterValues: null,
                inputValues: null,
                page:this.state.page,
                rowsPerPage: this.state.rowsPerPage
               }
    );
    }
    handleShowCrewModal() {
        this.setState({ showDMCrewModal: true })
    }
    handleHideCrewModal() {
        this.setState({ showDMCrewModal: false })
    }
    getCrewData() {
        this.setState({ fetchingCrewDetails: true })
        CasterService.getData(Constants.CasterServiceBaseUrl +
            `/casterFeatureProjectCrew?project_id=${this.props?.project_id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response?.data?.error || !response?.data) {
                    this.setState({ fetchingCrewDetails: false })
                }
                else {
                    let crewList = response?.data?.map(item => {
                        let phoneString = '', emailString = ''
                        item?.phone?.map(i => {
                            if (i?.is_primary == 1) {
                                phoneString = phoneString + `${i?.phone} <span style="font-size:15px;color:#164f9f;">&starf;</span>\n`
                            }
                            else {
                                phoneString = phoneString + i?.phone + '\n'
                            }
                        })
                        item?.email?.map(i => {
                            if (i?.is_primary == 1) {
                                emailString = emailString + `${i?.email} <span style="font-size:15px;color:#164f9f;">&starf;</span>\n`
                            }
                            else {
                                emailString = emailString + i?.email + '\n'
                            }
                        })

                        return {
                            project_id: item?.project_id,
                            crew_id: item?.crew_id,
                            project_crew_id: item?.project_crew_id,
                            crew_name: item?.crew_name,
                            location_name: item?.location_name,
                            crew_occupation: item?.crew_occupation,
                            company: item?.company,
                            phone: phoneString,
                            email: emailString
                        }
                    })
                    this.setState({ fetchingCrewDetails: false, crewList: crewList })

                }

            }, err => {
                this.setState({ fetchingCrewDetails: false })
                console.log("error from project crew details")
            })
    }
    handleShowHistoricalData = (event) => {
        this.setState({
            showHistoricalData: event.target.checked ? 1 : 0
        }, () => {
            let payload = JSON.parse(atob(dashboardPayload.encryptedString));
            payload.show_historical_data = this.state.showHistoricalData;
            payload.project_id = '';
            payload = btoa(JSON.stringify(payload));
            dashboardPayload.encryptedString = payload;
            const load = JSON.parse(JSON.stringify({ ...dashboardPayload }));
            this.getDashboardData(load);
        })
        // let inputFilters = JSON.parse(JSON.stringify({ ...this.state.inputFilters }));
        // inputFilters['show_historical_data'] = event.target.checked ? 1 : 0;
        // inputFilters['page_count'] = this.state.rowsPerPage;
        // this.setState({
        //     inputFilters, showHistoricalData: event.target.checked
        // }, () => {
        //     this.getDashboard(this.state.inputFilters);
        // })
    }
    reverseSSNVisibility = (index) => {
        setTimeout(() => {
            let tempRenderList = [...this.state.initialRenderList];
            tempRenderList[index].isSSNVisible = false;
            this.setState({ initialRenderList: tempRenderList });
        }, 3000)
    }
    filterMethod = (colName) => {
        let filters = JSON.parse(JSON.stringify({ ...this.state.dashboardFilters }));
        // filters["order_by"] = this.state.isSortByAsc === true ? "ASC" : "DESC";
        // filters["order_by_clause"] = colName;
        this.setState({
            sortBy: colName,
            // dashboardFilters: filters,
        },
            () => {
                let payload = JSON.parse(atob(dashboardPayload.encryptedString));
                payload.show_historical_data = this.state.showHistoricalData;
                if (colName === 'Project Title') {
                    payload.order_by = 'project';
                } else if (colName === 'Production #') {
                    payload.order_by = 'prod_no';
                }
                else {
                    payload.order_by = colName;
                }
                payload.sort_by = this.state.isSortByAsc === true ? "ASC" : "DESC";
                payload.project_id = '';
                payload = btoa(JSON.stringify(payload));               
                dashboardPayload.encryptedString = payload;
                const load = JSON.parse(JSON.stringify({ ...dashboardPayload }));
                this.getDashboardData(load);
            }
            //() => this.pickMethod(this.state.tabName)
        )
    }
    handleTableSort = (colName) => {
        let isSortByAsc = this.state.isSortByAsc;
        isSortByAsc = this.state.sortCount % 2 === 0 ? true : false,
            this.setState({
                isSortByAsc,
                config: updateTableSortConfigObject(this.state.config, isSortByAsc, this.state.sortBy, colName)
            }, () => {
                this.filterMethod(colName)
            })
    }
    handleSortColumn = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }
    onEyeClick = (index) => {
        let tempList = [...this.state.initialRenderList];
        tempList[index].isSSNVisible = true;
        this.setState({ initialRenderList: tempList }, () => this.reverseSSNVisibility(index));
    }
    addNewProject = () => {
        this.setState({ showNewProduction: true })
    }
    expandRow = (index) => {

    }
    render() {
        const url = `/caster-feature/project/${this.state.lastAccessedProjectId}`;
        let message = this.state.showCols ? this.state.maxColErrorMessage : this.state.showMinCols ? this.state.minColErrorMessage : "";
        let comfirmModalTitle = this.state.showCols ? this.state.showMaxCols : this.state.showMinCols ? this.state.showMinColsAlert : "";
        return (

            <MDBContainer fluid className="dashBoardWrapper">
                <MDBRow>
                    <MDBCol md={10}><span className="landingPageUserNameFeature">
                        Welcome,{`${this.props?.userContext?.user_profile?.name ? (' ' + this.props?.userContext?.user_profile?.name) : null}`}
                    </span></MDBCol>
                    <MDBCol md={2} className="lastAccessedCol">
                        <MDBRow>
                            <span>Last Accessed</span>
                        </MDBRow>
                        <MDBRow>
                            {this.state?.last_accessed_project ?
                                <span>
                                    <Link to={url}>{`${this.state?.last_accessed_project}`}</Link>
                                </span>
                                : ''
                            }
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    {this.state.isDashboardLoading ? (
                        <div className="ContentLoader">
                            <CircularProgress />
                        </div>
                    ) : (
                            <div>
                            
                                <div className="dashBoard">
                                {this.state.isFetchingData && 
                                <div className="dashboardadingSpinner"><CircularProgress size={20} /></div>}
                                    {/* new table */}
                                    {
                                    //this.state?.initialRenderList?.length > 0 &&
                                        <>  <TableComponent
                                            className={"dashboard-table"}
                                            list={this.state.initialRenderList || []}
                                            config={this.state.config}
                                            postInitiated={this.state.isDashboardLoading}
                                            hyperLinkNavigationCallback={this.navigationCallback}
                                            favIconCallback={(item, id) => this.handleFavIcon(item, id)}
                                            options={this.state}
                                            isLandingPage={true}
                                            onColumnCheck={this.onColumnCheck.bind(this)}
                                            applyFilter={this.applyFilterLandingPage.bind(this)}
                                            onEyeClick={this.onEyeClick.bind(this)}
                                            sortCallback={this.handleSortColumn}
                                            addNewProject={this.addNewProject.bind(this)}
                                            isExpanded={this.state.config.headings.filter(item => item.isColumn === true).length > 7 ? true : false}
                                            stickyHeader={true}
                                            selectedExpandedData={this.state.selectedExpandedData}
                                            selectedRowIndex={this.state.selectedRowIndex}
                                            isLoading={this.state.isDashboardLoading}
                                            isExpandLoading={this.state.isFetchingData}
                                            expandStatus={this.state.expandStatus}
                                            showHistoricalData={this.state.showHistoricalData}
                                            handleShowHistoricalData={this.handleShowHistoricalData.bind(this)}
                                            showSelectedColumnCount={this.state.showSelectedColumnCount}
                                        />
                                         {/* {console.log(this.state.count)}
                                        {console.log(this.state.page)}
                                        {console.log(this.state.rowsPerPage)}
                                        {console.log(this.state.handleChangePage)}
                                        {console.log(this.state.handleChangeRowsPerPage)} */}
                                        
                                            <TablePagination
                                                component="div"
                                                count={this.state.count}
                                                // rowsPerPageOptions={this.state.rowsPerPage}
                                                page={this.state.page - 1}
                                                onChangePage={this.handleChangePage}
                                                rowsPerPage={this.state.rowsPerPage}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            /> </>
                                   }
                                </div>
                                <MessageModal
                                    open={this.state.showCols || this.state.showMinCols || false}
                                    title={comfirmModalTitle}
                                    message={message}
                                    hideCancel={this.state.showCols || this.state.showMinCols || false}
                                    primaryButtonText={"OK"}
                                    secondaryButtonText={"Cancel"}
                                    onConfirm={() => this.setState({ showCols: false, showMinCols: false })}
                                    handleClose={(e) => this.setState({ showCols: false, showMinCols: false })} />
                            </div>
                        )}
                </MDBRow>
                {this.state.showNewProduction ?
                    <Modal
                        open={this.state.showNewProduction}
                        handleClose={this.handleHideProductionPopup.bind(this)}
                        modalName={"Project"}
                        headerText={"Project"}
                        mode="New"
                        toggleRefreshPage={this.props.toggleRefreshPage}
                        className='ProjectDetailsContainer'
                        {...this.props}
                    /> : null
                }
                {this.state.showDMCrewModal ?
                    <>
                        <ModalComponent
                            id={this.state.selectedCrewDetails?.value}
                            akas={this.state?.selectedCrewDetails?.aka}
                            open={this.state.showDMCrewModal}
                            handleClose={this.handleHideCrewModal.bind(this)}
                            fetchDetailsCallback={this.getCrewData.bind(this)}
                            tabList={crewNavList}
                            headerText={"Crew"}
                            mode="edit"
                            isProfileDiv={true}
                            addButtonText={"New Crew"}
                            objectType={'CREW'}
                            objectId={this.state?.selectedCrewDetails?.value}
                            dataUrl='casterFeatureCrewDetails'
                            headerName={this.state.selectedCrewDetails?.headerName}
                            navFuns={this.functions}
                            {...this.props}
                        /></> : null
                }
            </MDBContainer>
        )
    }

}
export default withUserContext(withFilterContext(DashboardLatest))
