export const list = [
    {
        project_id: 7,
        project_name: 'Porject 1',
        project_class: 'SSN',
        project_equation: 'SSN1'
    },
    {
        project_id: 2,
        project_name: 'Project 2',
        project_class: 'SSN',
        project_equation: 'SSN1'
    },
]

export const config = {
    headings: [
        {
            headingLabel: "Project",
            border: true,
            width: "45%"
        },
        {
            headingLabel: "Project Type",
            border: true,
            width: "45%"
        },
        {
            headingLabel: "Contract Type",
            border: true,
            width: "45%"
        }
    ],
    dataNodes: ["project_name", "project_class", "project_equation"],
    primaryKey: "project_id",
    hyperlinks: ["project_name"]
}

export const filtersSearch = {
    "per_page": 10,
    "page": 1,
    "order_by": "PROJECT",
    "sort_by": "ASC",
    "search_text": ""
}

export const landingConfig = {
    headings: [
        {
            headingLabel: " ",
            width: "5%"
        },
        {
            headingLabel: "Project Name",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Start-Wrap",
            width: "20%"
        },
        {
            headingLabel: "Production Company",
            width: "35%"
        },
        {
            headingLabel: "SAP/GL #Prod",
            width: "15%"
        },
        {
            headingLabel: "SAP/GL #Dev",
            width: "15%"
        },
        {
            headingLabel: "Status",
            width: "10%"
        }
    ],
    dataNodes: ["is_favorite", "project_name", "start_wrap_date", "production_company", "gl_no_prod", "gl_no_dev", "status"],
    primaryKey: "project_id",
    // actions:["ellipsis-h"],
    hyperlinks: ["project_name"],
    nodeFields: ["is_favorite"],
    // buttonWithText:["Select Season"]
};

export const sortByValues = [
    {
        value: "PROJECT",
        label: "PROJECT"
    },
    {
        value: "START_DATE",
        label: "START_DATE"
    },
    {
        value: "WRAP_DATE",
        label: "WRAP_DATE"
    },
    {
        value: "RELEASE_DATE",
        label: "RELEASE_DATE"
    },
    {
        value: "PRODUCTION_COMPANY",
        label: "PRODUCTION_COMPANY"
    },
    {
        value: "DISTRIBUTOR",
        label: "DISTRIBUTOR"
    },
    {
        value: "GL_NO_PROD",
        label: "GL_NO_PROD"
    },
    {
        value: "GL_NO_DEV",
        label: "GL_NO_DEV"
    },
    {
        value: "STATUS",
        label: "STATUS"
    }
]
export const dashboardFiltersSearch = {
    "per_page": 35,
    "page": 1,
    "order_by": "PROJECT",
    "sort_by": "ASC",
    "search_text": "",
    "show_historical_data": 0,
    "project_id": null
}
export const dashboardTableConfig = {
    iscolumnFilterRequired: true,
    headings: [
        {
            headingLabel: "Project Title",
            isColumn: true,
            icon: "",
            width: "14.28%",
            isDisabled: true,
            dataNode: "project_name",
            searchJson: "search_project",
            inputType: "text",
            maxCharacterLength: 35,
            defaultColumn: true,
            letterCasing:'text-uppercase',
            hasMultipleValue:true,
            hyperLink:true,
            rowSpanColumn:'performer',
            sortColumn:true
        },
        {
            headingLabel: "Performer",
            isColumn: true,
            icon: "",
            width: "14.28%",
            dataNode: "performer_name",
            searchJson: "search_performer",
            inputType: "text",
            maxCharacterLength: 35,
            defaultColumn: true,
            letterCasing:'text-capitalize',
            hasMultipleValue:true,
            hyperLink:true,
            isDisabled: true,
        },
        {
            headingLabel: "Role",
            isColumn: true,
            icon: "",
            width: "14.28%",
            dataNode: "role",
            searchJson: "search_role",
            inputType: "text",
            maxCharacterLength: 35,
            defaultColumn: true,
            letterCasing:'text-capitalize',
            hasMultipleValue:true,
            hyperLink:true
        },
        {
            headingLabel: "SSN",
            isColumn: true,
            icon: "",
            width: "14.28%",
            dataNode: "ssn",
            searchJson: "search_ssn",
            inputType: "text",
            maxCharacterLength: 35,
            defaultColumn: true,
            hasMultipleValue:true
        },
        {
            headingLabel: "Union",
            isColumn: true,
            icon: "",
            width: "14.28%",
            dataNode: "union",
            searchJson: "search_union",
            inputType: "text",
            maxCharacterLength: 35,
            defaultColumn: true,
            color:'gray',
            letterCasing:'text-uppercase',
            hasMultipleValue:true
        },        
        {
            headingLabel: "Performer Phone",
            isColumn: false,
            icon: "",
            width: "14.28%",
            dataNode: "performer_phone",
            searchJson: "search_performer_phone",
            inputType: "text",
            maxCharacterLength: 35,
            defaultColumn: false,
            letterCasing:'text-capitalize',
            hasMultipleValue:true
        },
        {
            headingLabel: "Performer Email",
            isColumn: false,
            icon: "",
            width: "14.28%",
            dataNode: "performer_email",
            searchJson: "search_performer_email",
            inputType: "text",
            maxCharacterLength: 35,
            defaultColumn: false,
            letterCasing:'text-capitalize',
            hasMultipleValue:true
        },
        {
            headingLabel: "Representative",
            isColumn: true,
            icon: "",
            width: "14.28%",
            dataNode: "representative_name",
            altDataNode:'representative',
            searchJson: "search_rep",
            inputType: "text",
            maxCharacterLength: 35,
            defaultColumn: true,
            color:'blue',
            letterCasing:'text-capitalize',
            hasMultipleValue:true,
            hyperLink:true
        },
        {
            headingLabel: "Representative Phone",
            isColumn: false,
            icon: "",
            width: "14.28%",
            dataNode: "representative_phone",
            altDataNode:'representative',
            searchJson: "search_representative_phone",
            inputType: "text",
            maxCharacterLength: 35,
            defaultColumn: false,
            letterCasing:'text-capitalize',
            hasMultipleValue:true,
        },
        {
            headingLabel: "Representative Email",
            isColumn: false,
            icon: "",
            width: "14.28%",
            dataNode: "representative_email",
            altDataNode:'representative',
            searchJson: "search_representative_email",
            inputType: "text",
            maxCharacterLength: 35,
            defaultColumn: false,
            letterCasing:'text-capitalize',
            hasMultipleValue:true
        },
        {
            headingLabel: "Agency",
            isColumn: false,
            icon: "",
            width: "14.28%",
            dataNode: "agency_name",
            searchJson: "search_agency",
            inputType: "text",
            maxCharacterLength: 35,
            defaultColumn: false,
            color:'blue',
            letterCasing:'text-capitalize',
            hasMultipleValue:true,
            hyperLink:true
        },
        {
            headingLabel: "Crew",
            isColumn: false,
            icon: "",
            width: "14.28%",
            dataNode: "crew_name",
            searchJson: "search_crew",
            inputType: "text",
            maxCharacterLength: 35,
            defaultColumn: false,
            color:'blue',
            letterCasing:'text-capitalize',
            hasMultipleValue:true,
            hyperLink:true,
            rowSpanColumn:'performer',
        },
        {
            headingLabel: "Occupation",
            isColumn: false,
            icon: "",
            width: "14.28%",
            dataNode: "crew_occupation",
            searchJson: "search_occupation",
            inputType: "text",
            maxCharacterLength: 35,
            defaultColumn: false,
            letterCasing:'text-capitalize',
            hasMultipleValue:true,
            rowSpanColumn:'performer',
        },
        {
            headingLabel: "Production #",
            isColumn: false,
            icon: "",
            width: "14.28%",
            dataNode: "gl_no_prod",
            searchJson: "search_prod_no",
            inputType: "text",
            maxCharacterLength: 35,
            defaultColumn: false,
            letterCasing:'text-capitalize',
            hasMultipleValue:true,
            sortColumn:true
        },
        {
            headingLabel: "Production Company",
            isColumn: false,
            icon: "",
            width: "14.28%",
            dataNode: "production_company",
            searchJson: "search_prod_comp",
            inputType: "text",
            maxCharacterLength: 35,
            defaultColumn: false,
            letterCasing:'text-uppercase',
            hasMultipleValue:true
        },
        {
            headingLabel: "Loanout",
            isColumn: false,
            icon: "",
            width: "14.28%",
            dataNode: "loanout_company",
            searchJson: "search_loanout",
            inputType: "text",
            maxCharacterLength: 35,
            defaultColumn: false,
            hasMultipleValue:true
        },
        {
            headingLabel: "EIN",
            isColumn: false,
            icon: "",
            width: "14.28%",
            dataNode: "e_in",
            searchJson: "search_loanout_no",
            inputType: "text",
            maxCharacterLength: 35,
            defaultColumn: false,
            hasMultipleValue:true
        },
        {
            headingLabel: "FID",
            isColumn: false,
            icon: "",
            width: "14.28%",
            dataNode: "f_id",
            searchJson: "search_fid",
            inputType: "text",
            maxCharacterLength: 20,
            defaultColumn: false,
            hasMultipleValue:true
        },
        {
            headingLabel: "SAG ID",
            isColumn: false,
            icon: "",
            width: "14.28%",
            dataNode: "sag_id",
            searchJson: "search_sag",
            inputType: "text",
            maxCharacterLength: 20,
            defaultColumn: false,
            hasMultipleValue:true
        },
        {
            headingLabel: " ",
            isColumn: false,
            icon: "",
            width: "5%",
            dataNode: "expand",
            defaultColumn: false
        }
    ],
    dataNodes: ["project_name", "performer_name", "role", "ssn", "union", "representative_name","representative_phone","representative_email", "agency_name", "crew_name", "contact", "crew_occupation", "gl_no_prod", "production_company", "loanout_no","f_id","sag_id", "expand"],
    // dataNodes: ["is_favorite","project_name", "start_wrap_date", "production_company", "gl_no_prod","gl_no_dev", "status"],
    primaryKey: "project_id",
    identityKey: "Dashboard",
    // actions:["ellipsis-h"],
    //actions: ["angle-double-right"],
    hyperlinks: ["project_name", "performer_name", "role", "representative_name", "agency_name", "crew_name", "expand"],
    //nodeFields: ["is_favorite"],
    filtersLanding: true,
    filtersLandingCasting: true,
    filterCallBack:true,
    filterCallBackKeyIdentifier:'Dashboard'
    // buttonWithText:["Select Season"]
};
export const crewNavList = [
    {
        label: "Personal",
        index: 0
    },
    {
        label: "Project History",
        index: 1
    }
]
export const DashboardFiltersSearchJson = {
    "project_name_search": null,
    "performer_name_search": null,
    "role_search": null,
    "ssn_search": null,
    "union_search": null,
    "representative_name_search": null,
    "agency_name_search": null,
    "crew_search": null,
    "contact_search": null,
    "occupation_search": null,
    "gl_no_prod_search": null,
    "production_company_search": null,
    "loanout_no_search": null,
    "search_fid": null,
    "search_sag": null,
    "page_count": 10,
    "page_no": 1
}
export const dashboardPayload = {
    payload: "casterFeatureProjectDashboard",
    encryptedString: btoa(JSON.stringify({
        "per_page": 25,
        "page": 1,
        "order_by": "PROJECT",
        "sort_by": "ASC",
        "search_text": null,
        "project_id": null,
        "show_historical_data": 0,
        "search_project":"","search_performer":"","search_role":"","search_union":"","search_ssn":"","search_rep":"","search_agency":"","search_crew":"",
        "search_contact":"",
        "search_performer_phone":"",
        "search_performer_email":"",
        "search_representative_email":"",
        "search_representative_phone":"",
        "search_occupation":"","search_prod_no":"","search_prod_comp":"","search_loanout":"","search_fid":"", "search_sag":"","search_loanout_no":""
    })),
    encryptionType: 1
}
export const clearDashboardPayload = {
    payload: "casterFeatureProjectDashboard",
    encryptedString: btoa(JSON.stringify({
        "per_page": 25,
        "page": 1,
        "order_by": "PROJECT",
        "sort_by": "ASC",
        "search_text": null,
        "project_id": null,
        "show_historical_data": 0,
        "search_project":"","search_performer":"","search_role":"","search_union":"","search_ssn":"","search_rep":"","search_agency":"","search_crew":"",
        "search_contact":"",
        "search_performer_phone":"",
        "search_performer_email":"",
        "search_representative_email":"",
        "search_representative_phone":"",
        "search_occupation":"","search_prod_no":"","search_prod_comp":"","search_loanout":"","search_fid":"", "search_sag":"","search_loanout_no":""
    })),
    encryptionType: 1
}
export const dashBoardFilterSearch = {
    "search_project":"",
    "search_performer":"",
    "search_role":"",
    "search_union":"",
    "search_ssn":"",
    "search_rep":"",
    "search_agency":"",
    "search_crew":"",
    "search_performer_phone":"",
    "search_performer_email":"",
    "search_representative_email":"",
    "search_representative_phone":"",
    "search_occupation":"",
    "search_prod_no":"",
    "search_prod_comp":"",
    "search_loanout":"",
    "search_fid":"",
    "search_sag":""

}

export const sxForTooltip = {
    tooltip: {
        sx: {
            maxWidth:'500px',
            fontSize:'13px',
            bgcolor: 'common.white',
            border: '1px solid #6c757d',
            color: 'common.black',
            '& .MuiTooltip-arrow': {
                color: 'common.white',
                '&::before':{
                    border: '1px solid #6c757d',
                  }
            },
        },
    },
}
// export const initialRenderList = [{
//     "project_id": 4288,
//     "project_name": "blood diamond BLOOD DIAMOND BLOOD DIAMOND BLOOD DIAMOND BLOOD DIAMOND BLOOD DIAMOND BLOOD DIAMOND BLOOD DIAMOND BLOOD DIAMOND BLOOD DIAMOND BLOOD DIAMOND BLOOD DIAMOND BLOOD DIAMOND| Project title alias 1 dummy | Alias 2 dummy",
//     "talent_id": 24433,
//     "performer_name": "Martin Abdul-Jabbar Martin Abdul-Jabbar Martin Abdul-Jabbar Martin Abdul-JabbarMartin Abdul-Jabbar Martin Abdul-Jabbar Martin Abdul-Jabbar Martin Abdul-Jabbar| Performer alias 1 dummy",
//     "performer_deal_id": 35358,
//     "role": "Role 1 qwerty qwerty qwerty qwerty  qwerty qwerty qwerty qwerty | Role 2 | Role 3",
//     "ssn": "614-37-3909",
//     "isSSNVisible": false,
//     "union": "SAG",
//     "representative_name": "LIBERTY PICTURES, INC.",
//     "expand": ">>"
// }, {
//     "project_id": 2454,
//     "project_name": "'87 FLEER",
//     "talent_id": 24433,
//     "performer_name": "Martin Abdul-Jabbar",
//     "performer_deal_id": 35358,
//     "role": "GUINEAN GUARD",
//     "ssn": "614-37-3908",
//     "isSSNVisible": false,
//     "union": "SAG",
//     "representative_name": "LIBERTY PICTURES, INC.",
//     "expand": ">>"
// },]